export default class StatusEntity {
    static getEntityData(elem: any) {
        return {
            uuid: elem.querySelector("#status_uuid") ? (elem.querySelector("#status_uuid") as HTMLInputElement).value : null,
            name: (elem.querySelector("#status_name") as HTMLInputElement).value,
            is_archive: (elem.querySelector("#status_is_archive") as HTMLInputElement).checked,
            // @ts-ignore
            transitions: Array.from(elem.querySelectorAll("#status_transitions option:checked")).map(o => o.value),
            // @ts-ignore
            users: Array.from(elem.querySelectorAll("#status_users option:checked")).map(o => o.value)
        }
    }
}